import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class DeliveryOrderService {
    readQuery(){
        var query = `
            query ($paging: ServerPaging, $status:String) {
                GetTransactionDeliveryOrder (Paging:$paging, Status:$status) {
                    transaction_delivery_order {
                        status
                        delivery_order_id
                        delivery_order_type_id
                        delivery_order_number
                        delivery_order_date
                        notes
                        sp_number
                        purchase_order_number
                        customer_name
                        project_name
                        attachment
                        created_by
                        created_at
                        driver_id
                        vehicle_id
                        expedition_id
                        additional_notes
                    }
                    total
                }
            }
        `;
        return query;
    }

    async getDeliveryOrderDetail(id){
        const variables = {
            id : id
        }
        var query = gql`
            query ($id:Int) {
                GetTransactionDeliveryOrder (DeliveryOrderID:$id ) {
                    transaction_delivery_order {
                        created_at
                        last_update
                        delivery_order_id
                        delivery_order_type_id
                        delivery_order_number
                        delivery_order_date
                        status
                        purchase_order_id
                        purchase_order {
                            purchase_order_date
                            status
                            address
                            project_id
                            project {
                                project_code
                            }
                            project_name
                            project_type
                            payment_method
                            customer_id
                            customer {
                                contact_name
                                metadata
                                attachment1
                            } 
                            invoice_number
                            term_of_payment
                        }
                        purchase_order_number
                        sp_number
                        project_name
                        customer_name
                        notes
                        attachment
                        created_by
                        driver_id
                        vehicle_id
                        expedition_id
                        additional_notes
                        delivery_order_detail {
                            created_at
                            last_update
                            delivery_order_id
                            item_id
                            item_name
                            product_code
                            barcode
                            type_detail_id
                            uom
                            ordered_quantity
                            delivered_quantity
                            remaining_quantity
                            quantity
                            notes
                            base_quantity
                            base_uom
                        }
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetTransactionDeliveryOrder.transaction_delivery_order[0];
    }

    GetDeliveryOrderableData(){
        var query = ` query{
            GetTransactionDeliveryOrderableItemListV2{
                purchase_order_id
                sp_number
                purchase_order_number
                purchase_order_date
                approver
                customer_name
                customer_meta
                project_type
                project_name
                project_detail
                sales_name
                delivery_order_amount
                purchase_order_to_delivery_order_item_amount
                term_of_payment
            }
        }`;
        return query;
    }

    async getDeliveryOrderablDataDetail(id){
        const variables = {
            id : [id]
        }
        var query = gql`
            query ($id:[Int]) {
                GetTransactionDeliveryOrderableItemListV2 (PurchaseOrderIds:$id) {
                    purchase_order_id
                    sp_number
                    purchase_order_number
                    purchase_order_date
                    approver
                    customer_name
                    customer_meta
                    project_type
                    project_name
                    project_detail
                    sales_name
                    delivery_order_amount
                    purchase_order_to_delivery_order_item_amount
                    delivery_orderable_detail {
                        purchase_order_id
                        item_id
                        item_name
                        product_code
                        barcode
                        type_detail_id
                        uom
                        ordered_quantity
                        delivered_quantity
                        remaining_quantity
                        quantity
                    }
                } 
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTransactionDeliveryOrderableItemListV2[0];
    }
    
    async getContactDetailQuery(variables){
        var query = gql`query ($type:String,$id:Int) {
            GetContact (ContactType:$type, ContactId:$id) {
                contact_id
                contact_code
                contact_type
                contact_name
                status
                metadata
                attachment1
            }
        }`;
        if (variables.id == '' || variables.id == null) {
            return null;
        }
        else {
            var result = await globalfunc.defaultApolloQueryDefault(query,variables);
            return result.data.GetContact[0];
        }
    }  

    async addQuery(variables){
        var query = gql`
            mutation ( $data:NewTransactionDeliveryOrder! ) {
                CreateTransactionDeliveryOrder ( New:$data )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
            mutation ( $id:Int!, $data:NewTransactionDeliveryOrder! ) {
                UpdateTransactionDeliveryOrder ( DeliveryOrderId:$id, New:$data )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    DeliveryOrderDetailTemplate(data){
        var po_time = moment(data.purchase_order.purchase_order_date).format('DD MMMM YYYY'); 
        var doNumber = data.delivery_order_number == null ? "" : data.delivery_order_number;
        var invoiceNumber = data.purchase_order.invoice_number == null ? "" : data.purchase_order.invoice_number;
        var notes = data.notes == null ? "" : data.notes;

        var html = `
        <div class="row p-1" width="100%">
            <div class="col-4 px-2">
                <label class="form-label"><b>PO Info</b></label>
                <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">No. PO</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.purchase_order_number +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">No. SP</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.sp_number +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Tgl. PO</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ po_time +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Cara Bayar</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.purchase_order.payment_method +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Jatuh Tempo</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.purchase_order.term_of_payment +` hari</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Note</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ notes +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">No. DO</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ doNumber +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">No. Invoice</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ invoiceNumber +`</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 px-2">
                <label class="form-label"><b>Customer Info</b></label>
                <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Nama</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.customer_name +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">No. Telepon</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.purchase_order.customer.metadata.profile.phone1 +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">No. HandPhone</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.purchase_order.customer.metadata.profile.handphone +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Fax</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.purchase_order.customer.metadata.profile.fax +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Email</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.purchase_order.customer.metadata.profile.email +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Alamat</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.purchase_order.customer.metadata.profile.address +`</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 px-2">
                <label class="form-label"><b>Project Info</b></label>
                <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Kode</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.purchase_order.project.project_code +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Nama</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.project_name +`</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        `;
        return html;
    }

    itemDOPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    qty_string : kendo.toString(data[i].quantity, "n0"),
                    qty : data[i].quantity,
                    uom : data[i].uom,
                    name : data[i].item_name + " @" + data[i].base_quantity + " " + data[i].base_uom,
                    note : data[i].notes
                }

                if(data[i].quantity > 0){
                    arrayObject.push(row);
                }
            }
        }
        return arrayObject;
    }

    async getContactQuery(type){
        const variables = {
            type : type
        }
        var query = gql`query($type:String){
            GetContact(ContactType:$type){
                contact_id
                contact_code
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value: result.data.GetContact[i].contact_id, 
                            label: result.data.GetContact[i].contact_name + " (" + result.data.GetContact[i].contact_code + ")" }
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    async getVehicleQuery(){
        var query = gql`query{
            GetMasterVehicle{
                vehicle_id
                license_plate
                vehicle_year
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        
        var arrayData = [];
        if(result.data.GetMasterVehicle != null){
            for (let i = 0; i < result.data.GetMasterVehicle.length; i++) {
                var str = { value: result.data.GetMasterVehicle[i].vehicle_id, 
                            label: result.data.GetMasterVehicle[i].license_plate + " (" + result.data.GetMasterVehicle[i].vehicle_year + ")" }
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    itemGridDataEditable(data) {
        var deliveryOrderDetailData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { 
                    item_id: data[i].item_id,
                    product_code : data[i].product_code,
                    item_name: data[i].item_name,
                    barcode: data[i].barcode,
                    type_detail_id: data[i].type_detail_id,
                    uom: data[i].uom,
                    ordered_quantity: data[i].ordered_quantity,
                    delivered_quantity: data[i].delivered_quantity,
                    remaining_quantity: data[i].remaining_quantity,
                    quantity: data[i].quantity,
                    notes: data[i].notes,
                    old_remaining_quantity: (data[i].remaining_quantity + data[i].quantity),
                }
                deliveryOrderDetailData.push(str);
            }
        }
        return globalfunc.objectToArrayConverter(deliveryOrderDetailData, "TransactionDO-ItemDODetail");
    }
}


export default new DeliveryOrderService();