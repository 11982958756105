import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class ProformaInvoiceDownPaymentService {
    async getCustomerQuery(){
        var query = gql`query{
            GetContact(ContactType:"Distributor"){
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value: result.data.GetContact[i].contact_id, 
                            label: result.data.GetContact[i].contact_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    async getDropdownPPNTax(){
        var query = gql`query{
            GetMasterTax{
                percentage
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetMasterTax != null){
            for (let i = 0; i < result.data.GetMasterTax.length; i++) {
                var str = { value:result.data.GetMasterTax[i].percentage, 
                            label:result.data.GetMasterTax[i].percentage}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getContactDetailQuery(variables){
        var query = gql`query($type:String,$id:Int){
            GetContact(ContactType:$type, ContactId:$id){
                contact_id
                contact_code
                contact_type
                contact_name
                status
                metadata
                attachment1
                on_going_purchase_order_total_by_customer_id
            }
        }`;
        if (variables.id == '' || variables.id == null) {
            return null;
        }
        else {
            var result = await globalfunc.defaultApolloQueryDefault(query,variables);
            return result.data.GetContact[0];
        }
    }  

    async getProjectDropdownByCustomerId(customerId, projectType){
        var projectTypeIndex = 0;
        if(projectType == "Project"){
            projectTypeIndex = 1
        }
        if(projectType == "Retail"){
            projectTypeIndex = 2
        }
        const variables = {
            contactId : customerId,
            projectType : projectTypeIndex,
            status : "Ready"
        }
        
        var query = gql`query($contactId:Int, $projectType:Int, $status:[String]){
            GetTransactionProject(ContactID:$contactId, ProjectType:$projectType, Status:$status){
                project{
                    project_id
                    project_name
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        if(result.data.GetTransactionProject == null){
            return [];
        }else{
            return globalfunc.objectToArrayConverter(result.data.GetTransactionProject.project, 'Transaction-PO-Project');
        }
    }

    async getProjectDetail(id){
        const variables = {
            id : id
        }
        
        var query = gql`query($id:Int){
            GetTransactionProject(ProjectId:$id){
                project{
                    project_id
                    project_code
                    project_name
                    forecast_type
                    sales_id
                    sales_name
                    price_category_id
                    storage_id
                    project_detail
                    project_type
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);

        return result.data.GetTransactionProject.project[0];
    }

    async getLimitRemaining(id, projectType){
        if(projectType == 'None'){
            var variables = {id : id};
            var query = gql`query($id:Int){
                GetTransactionPurchaseOrder(ProjectID:$id){
                    transaction_purchase_order{
                        on_going_purchase_order_total_by_project_id
                    }
                }
            }`;
            var result = await globalfunc.defaultApolloQueryDefault(query, variables);
            var data = result.data.GetTransactionPurchaseOrder.transaction_purchase_order;
            
            var total = 0;
            if(data != null){
                if(data.length > 0){
                    total = data[0].on_going_purchase_order_total_by_project_id;
                }
            }

            return total;
        }else{
            return 0;
        }
    }

    poDataDropDown(totalPO){
        var poData = [];

        if(totalPO < 1){
            poData = [{value: 0}];
        }else{
            for (let i = 1; i <= totalPO; i++) {
                var str = {value: i}
                poData.push(str);
            }
        }

        return poData;
    }

    async readTypeDetailQuery(){
        var query = gql`query{
            GetTypeDetail(Flags:["All","Project","Retail"]) {
                type_detail_id
                type_detail_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetTypeDetail;
        var typeDetailData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { 
                    value: data[i].type_detail_id, 
                    label: data[i].type_detail_id + ' (' + data[i].type_detail_name + ')'
                }
                typeDetailData.push(str);
            }
        }
        return typeDetailData
    }

    readItemQuery(){
        var query = `query($id: Int!, $po: Int, $typeDetailId: String){
            GetTransactionPurchaseOrderableItemList(ProjectID: $id, PurchaseOrderSequence: $po, TypeDetailId: $typeDetailId){
                project_id
                purchase_order_sequence
                storage_id
                storage_name
                price_category_id
                price_category_name
                item_id
                product_code
                item_name
                barcode
                item_type
                type_detail_id
                type_detail_name
                base_uom
                uom
                conv_amount
                base_stock
                stock
                forcast_quantity
                ordered_quantity
                remaining_quantity
                quantity
                base_weight
                discount1
                discount2
                discount3
                discount4
                discount5
                discount_cash
                discount_amount_1
                discount_amount_2
                discount_amount_3
                discount_amount_4
                discount_amount_5
                discount_amount_cash
                price_retail
                price_project
                base_price_retail
                base_price_project
                price_retail_discount
                price_project_discount
                base_price_retail_discount
                base_price_project_discount
                price_retail_discount_cash
                price_project_discount_cash
                base_price_retail_discount_cash
                base_price_project_discount_cash
            }
        }`;
        return query;
    }

    fillSelectedQuantity(data, forecast_type){
        if(forecast_type == 'Forecast 1' || forecast_type == 'Paket'){
            //do nothing
        }else{
            data.forEach(function(data, key) {
                if(forecast_type == 'None' || forecast_type == 'Total'){
                    //do nothing
                }else{
                    data.selected_qty = data.forcast_quantity;
                }
            });
        }
        
        return data;
    }

    columnsProject(type, forecast_type){
        var price = "";
        var quantity = "";
        var columns = [];

        if(type == 'Project'){
            price = 'price_project';
        }else if(type == 'Retail'){
            price = 'price_retail';
        }

        if(forecast_type == 'Forecast 1' || forecast_type == 'Paket'){
            quantity = 'forcast_quantity';
        }else{
            quantity = 'selected_qty';
        }

        if(forecast_type == 'Forecast 2'){
            columns = [
                { field: "type_detail_name", title: "Tipe Detail", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "product_code", title: "Kode", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                // { field: "stock", title: "Stock", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "uom", title: "Satuan", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "conv_amount", title: "Nilai Satuan", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: price, title: "Harga", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, format:"{0:N0}" },
                { field: "forcast_quantity", title: "Limit Quantity PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: quantity, title: "Quantity", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount1", title: "Diskon 1 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount2", title: "Diskon 2 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount3", title: "Diskon 3 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount4", title: "Diskon 4 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount5", title: "Diskon 5 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount_cash", title: "Diskon Cash (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
            ];
        }else{
            columns = [
                { field: "type_detail_name", title: "Tipe Detail", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "product_code", title: "Kode", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                // { field: "stock", title: "Stock", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "uom", title: "Satuan", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "conv_amount", title: "Nilai Satuan", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: price, title: "Harga", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, format:"{0:N0}" },
                { field: quantity, title: "Quantity", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount1", title: "Diskon 1 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount2", title: "Diskon 2 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount3", title: "Diskon 3 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount4", title: "Diskon 4 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount5", title: "Diskon 5 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount_cash", title: "Diskon Cash (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
            ];
        } 

        return columns;
    }

    itemDataObject(data, qty, type){
        var price = 0;
        var net = 0;

        if(type == 'Project'){
            price = data.price_project;
            net = data.price_project_discount_cash;
        }else if(type == 'Retail'){
            price = data.price_retail;
            net = data.price_retail_discount_cash;
        }

        var price_discount = data.discount_amount_1 + data.discount_amount_2 + data.discount_amount_3 + data.discount_amount_4 + data.discount_amount_5 + data.discount_amount_cash;
        var real_qty = data.conv_amount * qty;
        var total_price = qty * net;

        var dataItem = [{
            item_id : data.item_id,
            product_code : data.product_code,
            item_name : data.item_name,
            price : price,
            price_discount : price_discount,
            net : net,
            qty : qty,
            current_qty : qty,
            real_qty : real_qty,
            total_price : total_price,
            discount1 : data.discount1,
            discount2 : data.discount2,
            discount3 : data.discount3,
            discount4 : data.discount4,
            discount5 : data.discount5,
            discount_cash : data.discount_cash,
            discount_amount_1 : data.discount_amount_1,
            discount_amount_2 : data.discount_amount_2,
            discount_amount_3 : data.discount_amount_3,
            discount_amount_4 : data.discount_amount_4,
            discount_amount_5 : data.discount_amount_5,
            discount_amount_cash : data.discount_amount_cash,
            stock : data.stock,
            conv_amount : data.conv_amount,
            uom : data.uom,
            type_detail_id: data.type_detail_id
        }];
        
        return dataItem;
    }

    itemGridChange(selectedItem, itemData, index){
        //change item
        var item = itemData;

        if(selectedItem.qty > selectedItem.stock){
            var real_qty = selectedItem.conv_amount * selectedItem.stock;
            var total_price = selectedItem.stock * selectedItem.net;

            item[index].qty = selectedItem.stock;
            item[index].real_qty = real_qty;
            item[index].total_price = total_price;
        }else{
            var real_qty = selectedItem.conv_amount * selectedItem.qty;
            var total_price = selectedItem.qty * selectedItem.net;

            item[index].qty = selectedItem.qty;
            item[index].real_qty = real_qty;
            item[index].total_price = total_price;
        }

        return item;
    }

    duplicateItemProjectChecker(oldData, newData){
        var duplicate = 0;
        for (let i = 0; i < oldData.length; i++){
            if(oldData[i].item_id == newData.item_id){
                duplicate++;
            }
        }
        return duplicate==0 ? false : true;
    }

    transactionProformaInvoiceDetail(itemDetail, tax){
        var result = [];
        
        if(itemDetail.length != 0){
            for (let i = 0; i < itemDetail.length; i++){
                var taxAmount = itemDetail[i].net * (tax / 100);
                var vatPerUnit = itemDetail[i].net + taxAmount;
                var sumQuantity = itemDetail[i].qty;

                var str = { 
                    item_id: itemDetail[i].item_id,
                    uom: itemDetail[i].uom,
                    price_per_unit: itemDetail[i].price,
                    price: itemDetail[i].price * sumQuantity,
                    price_discount_per_unit: itemDetail[i].net,
                    price_discount: itemDetail[i].net * sumQuantity,
                    vat_per_unit: vatPerUnit ,
                    vat: vatPerUnit * sumQuantity,
                    quantity: itemDetail[i].qty,
                    discount1: itemDetail[i].discount1,
                    discount2: itemDetail[i].discount2,
                    discount3: itemDetail[i].discount3,
                    discount4: itemDetail[i].discount4,
                    discount5: itemDetail[i].discount5,
                    discount_cash: itemDetail[i].discount_cash,
                    discount1_amount: itemDetail[i].discount_amount_1,
                    discount2_amount: itemDetail[i].discount_amount_2,
                    discount3_amount: itemDetail[i].discount_amount_3,
                    discount4_amount: itemDetail[i].discount_amount_4,
                    discount5_amount: itemDetail[i].discount_amount_5,
                    discount_cash_amount: itemDetail[i].discount_amount_cash,
                    tax_amount: taxAmount
                };
                result.push(str);
            }
        }
        
        return result;
    }

    async addQuery(variables){
        var query = gql`mutation($data:NewTransactionProformaInvoice!){
            CreateTransactionProformaInvoice(New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!, $data:NewTransactionProformaInvoice!){
            UpdateTransactionProformaInvoice(ProformaInvoiceId:$id, New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    readProformaInvoiceQuery(){
        var query = `query($paging:ServerPaging, $status:String){
            GetTransactionProformaInvoice(Paging:$paging, Status:$status){
                transaction_proforma_invoice{
                    proforma_invoice_id
                    proforma_invoice_number
                    proforma_invoice_date
                    status
                    project_id
                    project_name
                    purchase_order_sequence
                    customer_id
                    customer_name
                    address
                    tax_percentage
                    tax_amount
                    down_payment_pct
                    down_payment
                    total
                    created_by
                    notes
                    
                }
                total
            }
        }`;
        return query;
    }

    async getProformaInvoiceQuery(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetTransactionProformaInvoice(ProformaInvoiceID:$id){
                transaction_proforma_invoice{
                    proforma_invoice_id
                    proforma_invoice_number
                    proforma_invoice_date
                    status
                    project_id
                    project_name
                    purchase_order_sequence
                    customer_id
                    customer_name
                    address
                    tax_percentage
                    tax_amount
                    down_payment_pct
                    down_payment
                    total
                    created_by
                    notes
                    transaction_proforma_invoice_details{
                        item_id
                        item_name
                        base_uom
                        uom
                        conv_amount
                        price_per_unit
                        price
                        price_discount_per_unit
                        price_discount
                        vat_per_unit
                        vat
                        quantity
                        discount1
                        discount2
                        discount3
                        discount4
                        discount5
                        discount_cash
                        discount1_amount
                        discount2_amount
                        discount3_amount
                        discount4_amount
                        discount5_amount
                        discount_cash_amount
                        tax_amount
                        product_code
                    }
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTransactionProformaInvoice.transaction_proforma_invoice[0];
    }

    editItemGridDataGenerator(data){
        var result = [];
        
        if(data != null){
            for (let i = 0; i < data.length; i++){
                var price_discount = data[i].discount1_amount + data[i].discount2_amount + data[i].discount3_amount + data[i].discount4_amount + data[i].discount5_amount + data[i].discount_cash_amount;
                var real_qty = data[i].conv_amount * data[i].quantity;
                var net = data[i].price_per_unit - price_discount;
                var total_price = data[i].quantity * net;

                var str = {
                    item_id : data[i].item_id,
                    product_code : data[i].product_code,
                    item_name : data[i].item_name,
                    price : data[i].price_per_unit,
                    price_discount : price_discount,
                    net : net,
                    qty : data[i].quantity,
                    current_qty : data[i].quantity_current,
                    real_qty : real_qty,
                    total_price : total_price,
                    discount1 : data[i].discount1,
                    discount2 : data[i].discount2,
                    discount3 : data[i].discount3,
                    discount4 : data[i].discount4,
                    discount5 : data[i].discount5,
                    discount_cash : data[i].discount_cash,
                    discount_amount_1 : data[i].discount1_amount,
                    discount_amount_2 : data[i].discount2_amount,
                    discount_amount_3 : data[i].discount3_amount,
                    discount_amount_4 : data[i].discount4_amount,
                    discount_amount_5 : data[i].discount5_amount,
                    discount_amount_cash : data[i].discount_cash_amount,
                    stock : 0,
                    conv_amount : data[i].conv_amount,
                    uom : data[i].uom,
                    type_detail_id: data[i].type_detail_id
                };
                if(data[i].quantity > 0){
                    result.push(str)
                }
            }
        }
        
        return result;
    }

    itemArray(data){
        var itemArray = [];
        for (let i = 0; i < data.length; i++){
            var str = { quantity: data[i].qty, 
                        uom: data[i].uom, 
                        base_quantity: data[i].real_qty,
                        product_code: data[i].product_code, 
                        item_name: data[i].item_name, 
                        vat_per_unit: data[i].net,
                        total_price: data[i].total_price,
                        type: 'Item' }
            if(data[i].qty > 0){
                itemArray.push(str);
            }
        }

        return itemArray;
    }

    itemPIPDF(itemDetail){
        var arrayObject = [];
        if(itemDetail.length != 0){
            for (let i = 0; i < itemDetail.length; i++){
                if(itemDetail[i].quantity > 0){
                    var price_discount = itemDetail[i].discount1_amount + itemDetail[i].discount2_amount + itemDetail[i].discount3_amount + itemDetail[i].discount4_amount + itemDetail[i].discount5_amount + itemDetail[i].discount_cash_amount;
                    var net = itemDetail[i].price_per_unit - price_discount;
                    var total_net = itemDetail[i].quantity * net;
                    var real_quantity = itemDetail[i].quantity * itemDetail[i].conv_amount

                    var str = { 
                        quantity_string : kendo.toString(itemDetail[i].quantity, "n0"),
                        quantity : itemDetail[i].quantity,
                        uom : itemDetail[i].uom,
                        name : itemDetail[i].item_name + " @" + real_quantity + " " + itemDetail[i].base_uom,
                        net : net,
                        total_net : total_net,
                        net_string : kendo.toString(net, "n0"),
                        total_net_string : kendo.toString(total_net, "n0"),
                        tax_amount : itemDetail[i].tax_amount
                    }
                                
                    arrayObject.push(str);
                }
            }
        }

        return arrayObject;
    }
}

export default new ProformaInvoiceDownPaymentService();